import { PhotoGallery } from 'components/PhotoGallery';
import { Title } from 'components/Title';
import { Text } from 'components/Text';

export const Entrevidas: React.FC = () => {
  const images = [
    '/images/pecas/entrevidas/palestra-denise-e-teatro-maio-365.jpg',
    '/images/pecas/entrevidas/3349_4697646569313_214421155_n.jpg',
    '/images/pecas/entrevidas/8780_402884339785788_2117517854_n.jpg',
    '/images/pecas/entrevidas/12664_4697658249605_1733754138_n.jpg',
    '/images/pecas/entrevidas/29561_402884469785775_892121865_n.jpg',
    '/images/pecas/entrevidas/29623_402883023119253_1799937457_n.jpg',
    '/images/pecas/entrevidas/30347_402882786452610_1224124274_n.jpg',
    '/images/pecas/entrevidas/30360_402884773119078_293731168_n.jpg',
    '/images/pecas/entrevidas/32292_402883453119210_486484612_n.jpg',
    '/images/pecas/entrevidas/39270_4697653889496_1622479977_n.jpg',
    '/images/pecas/entrevidas/59686_402884306452458_2056699141_n.jpg',
    '/images/pecas/entrevidas/68474_402884493119106_1862895669_n.jpg',
    '/images/pecas/entrevidas/156677_4697659969648_1681968141_n.jpg',
    '/images/pecas/entrevidas/198118_4885790232787_1443784785_n.jpg',
    '/images/pecas/entrevidas/205608_402882696452619_1059764493_n.jpg',
    '/images/pecas/entrevidas/215837_402883959785826_1186906072_n.jpg',
    '/images/pecas/entrevidas/224094_402882733119282_1589269544_n.jpg',
    '/images/pecas/entrevidas/283317_402884666452422_1762272375_n.jpg',
    '/images/pecas/entrevidas/293833_4697658809619_1907111876_n.jpg',
    '/images/pecas/entrevidas/302863_4697645529287_85037180_n.jpg',
    '/images/pecas/entrevidas/314140_4697654569513_1451455646_n.jpg',
    '/images/pecas/entrevidas/377528_4697639929147_1614725650_n.jpg',
    '/images/pecas/entrevidas/386433_402882629785959_1187607647_n.jpg',
    '/images/pecas/entrevidas/389439_4697659689641_821857001_n.jpg',
    '/images/pecas/entrevidas/389486_4697644169253_685187720_n.jpg',
    '/images/pecas/entrevidas/389536_4697626768818_1555631260_n.jpg',
    '/images/pecas/entrevidas/399334_4878775657427_837947197_n.jpg',
    '/images/pecas/entrevidas/406876_4697650889421_1063163148_n.jpg',
    '/images/pecas/entrevidas/427914_4697661009674_573941821_n.jpg',
    '/images/pecas/entrevidas/527390_4697644609264_1960230670_n.jpg',
    '/images/pecas/entrevidas/530612_4697628088851_804228116_n.jpg',
    '/images/pecas/entrevidas/530621_4697649529387_1736118555_n.jpg',
    '/images/pecas/entrevidas/532778_378970672181111_589560067_n.jpg',
    '/images/pecas/entrevidas/534554_4697657609589_39079703_n.jpg',
    '/images/pecas/entrevidas/546918_4697643129227_2031339662_n.jpg',
    '/images/pecas/entrevidas/550185_4697653249480_458719727_n.jpg',
    '/images/pecas/entrevidas/550200_4697655009524_498536954_n.jpg',
    '/images/pecas/entrevidas/550328_4697645169278_1679053189_n.jpg',
    '/images/pecas/entrevidas/559517_4697640409159_1549038846_n.jpg',
    '/images/pecas/entrevidas/560854_4697648449360_1258136744_n.jpg',
    '/images/pecas/entrevidas/561724_402883926452496_2012485911_n.jpg',
    '/images/pecas/entrevidas/562054_4697629368883_713255511_n.jpg',
    '/images/pecas/entrevidas/564970_4697643729242_1790295139_n.jpg',
    '/images/pecas/entrevidas/576277_4697613328482_1667653980_n.jpg',
    '/images/pecas/entrevidas/603903_4885791912829_1681958016_n.jpg',
    '/images/pecas/entrevidas/603931_402885916452297_306944660_n.jpg',
    '/images/pecas/entrevidas/foto-programacao-reecarnatoria.jpg',
    '/images/pecas/entrevidas/IMG_2511.JPg',
    '/images/pecas/entrevidas/IMG_2513.JPg',
    '/images/pecas/entrevidas/IMG_2517.JPg',
    '/images/pecas/entrevidas/IMG_2518.JPg',
    '/images/pecas/entrevidas/IMG_2520.JPg',
    '/images/pecas/entrevidas/IMG_2521.JPg',
    '/images/pecas/entrevidas/IMG_2524.JPg',
    '/images/pecas/entrevidas/IMG_2528.JPg',
    '/images/pecas/entrevidas/IMG_2530.JPg',
    '/images/pecas/entrevidas/IMG_2534.JPg',
    '/images/pecas/entrevidas/IMG_2536.JPg',
    '/images/pecas/entrevidas/IMG_2537.JPg',
    '/images/pecas/entrevidas/IMG_2538.JPg',
    '/images/pecas/entrevidas/IMG_2541.JPg',
    '/images/pecas/entrevidas/IMG_2542.JPg',
    '/images/pecas/entrevidas/IMG_2543.JPg',
    '/images/pecas/entrevidas/IMG_2544.JPg',
    '/images/pecas/entrevidas/IMG_2546.JPg',
    '/images/pecas/entrevidas/IMG_2547.JPg',
    '/images/pecas/entrevidas/IMG_2555.JPg',
    '/images/pecas/entrevidas/IMG_2556.JPg',
    '/images/pecas/entrevidas/IMG_2557.JPg',
    '/images/pecas/entrevidas/IMG_2559.JPg',
    '/images/pecas/entrevidas/IMG_2560.JPg',
    '/images/pecas/entrevidas/IMG_2561.JPg',
    '/images/pecas/entrevidas/IMG_2564.JPg',
    '/images/pecas/entrevidas/IMG_2567.JPg',
    '/images/pecas/entrevidas/IMG_2568.JPg',
    '/images/pecas/entrevidas/IMG_2569.JPg',
    '/images/pecas/entrevidas/IMG_2571.JPg',
    '/images/pecas/entrevidas/IMG_2576.JPg',
    '/images/pecas/entrevidas/IMG_2578.JPg',
    '/images/pecas/entrevidas/IMG_2597.JPg',
    '/images/pecas/entrevidas/IMG_2598.JPg',
    '/images/pecas/entrevidas/IMG_2599.JPg',
    '/images/pecas/entrevidas/IMG_2600.JPg',
    '/images/pecas/entrevidas/IMG_2602.JPg',
    '/images/pecas/entrevidas/IMG_2605.JPg',
    '/images/pecas/entrevidas/IMG_2607.JPg',
    '/images/pecas/entrevidas/IMG_2609.JPg',
    '/images/pecas/entrevidas/IMG_2610.JPg',
    '/images/pecas/entrevidas/IMG_2611.JPg',
    '/images/pecas/entrevidas/IMG_2612.JPg',
    '/images/pecas/entrevidas/IMG_2613.JPg',
    '/images/pecas/entrevidas/IMG_2617.JPg',
    '/images/pecas/entrevidas/IMG_2618.JPg',
    '/images/pecas/entrevidas/IMG_2623.JPg',
    '/images/pecas/entrevidas/IMG_2624.JPg',
    '/images/pecas/entrevidas/IMG_2625.JPg',
    '/images/pecas/entrevidas/IMG_2628.JPg',
    '/images/pecas/entrevidas/IMG_2629.JPg',
    '/images/pecas/entrevidas/IMG_2632.JPg',
    '/images/pecas/entrevidas/IMG_2633.JPg',
    '/images/pecas/entrevidas/IMG_2635.JPg',
    '/images/pecas/entrevidas/IMG_2637.JPg',
    '/images/pecas/entrevidas/IMG_2638.JPg',
    '/images/pecas/entrevidas/IMG_2639.JPg',
    '/images/pecas/entrevidas/IMG_2641.JPg',
    '/images/pecas/entrevidas/IMG_2643.JPg',
    '/images/pecas/entrevidas/IMG_2645.JPg',
    '/images/pecas/entrevidas/IMG_2646.JPg',
    '/images/pecas/entrevidas/IMG_2648.JPg',
    '/images/pecas/entrevidas/IMG_2649.JPg',
    '/images/pecas/entrevidas/IMG_2653.JPg',
    '/images/pecas/entrevidas/IMG_2654.JPg',
    '/images/pecas/entrevidas/IMG_2655.JPg',
    '/images/pecas/entrevidas/IMG_2656.JPg',
    '/images/pecas/entrevidas/IMG_2657.JPg',
    '/images/pecas/entrevidas/IMG_2658.JPg',
    '/images/pecas/entrevidas/IMG_2664.JPg',
    '/images/pecas/entrevidas/IMG_2673.JPg',
    '/images/pecas/entrevidas/IMG_2674.JPg',
    '/images/pecas/entrevidas/IMG_2675.JPg',
    '/images/pecas/entrevidas/IMG_2676.JPg',
    '/images/pecas/entrevidas/IMG_2677.JPg',
    '/images/pecas/entrevidas/IMG_2678.JPg',
    '/images/pecas/entrevidas/IMG_2681.JPg',
    '/images/pecas/entrevidas/IMG_2682.JPg',
    '/images/pecas/entrevidas/IMG_2683.JPg',
    '/images/pecas/entrevidas/IMG_2684.JPg',
    '/images/pecas/entrevidas/IMG_2685.JPg',
    '/images/pecas/entrevidas/IMG_2686.JPg',
    '/images/pecas/entrevidas/IMG_2690.JPg',
    '/images/pecas/entrevidas/IMG_2692.JPg',
    '/images/pecas/entrevidas/IMG_2693.JPg',
    '/images/pecas/entrevidas/IMG_2694.JPg',
    '/images/pecas/entrevidas/IMG_2697.JPg',
    '/images/pecas/entrevidas/IMG_2698.JPg',
    '/images/pecas/entrevidas/IMG_2699.JPg',
    '/images/pecas/entrevidas/IMG_2701.JPg',
    '/images/pecas/entrevidas/IMG_2702.JPg',
    '/images/pecas/entrevidas/IMG_2705.JPg',
    '/images/pecas/entrevidas/IMG_2706.JPg',
    '/images/pecas/entrevidas/IMG_2708.JPg',
    '/images/pecas/entrevidas/IMG_2713.JPg',
    '/images/pecas/entrevidas/IMG_2714.JPg',
    '/images/pecas/entrevidas/IMG_2715.JPg',
    '/images/pecas/entrevidas/IMG_2717.JPg',
    '/images/pecas/entrevidas/IMG_2718.JPg',
    '/images/pecas/entrevidas/IMG_2719.JPg',
    '/images/pecas/entrevidas/IMG_2720.JPg',
    '/images/pecas/entrevidas/IMG_2721.JPg',
    '/images/pecas/entrevidas/IMG_2723.JPg',
    '/images/pecas/entrevidas/IMG_2724.JPg',
    '/images/pecas/entrevidas/IMG_2726.JPg',
    '/images/pecas/entrevidas/IMG_2728.JPg',
    '/images/pecas/entrevidas/IMG_2730.JPg',
    '/images/pecas/entrevidas/IMG_2732.JPg',
    '/images/pecas/entrevidas/IMG_2736.JPg',
    '/images/pecas/entrevidas/IMG_2738.JPg',
    '/images/pecas/entrevidas/IMG_2739.JPg',
    '/images/pecas/entrevidas/IMG_2741.JPg',
    '/images/pecas/entrevidas/IMG_2744.JPg',
    '/images/pecas/entrevidas/IMG_2748.JPg',
    '/images/pecas/entrevidas/IMG_2750.JPg',
    '/images/pecas/entrevidas/IMG_2752.JPg',
    '/images/pecas/entrevidas/IMG_2753.JPg',
    '/images/pecas/entrevidas/IMG_2757.JPg',
    '/images/pecas/entrevidas/IMG_2759.JPg',
    '/images/pecas/entrevidas/IMG_2762.JPg',
    '/images/pecas/entrevidas/IMG_2765.JPg',
    '/images/pecas/entrevidas/IMG_2766.JPg',
    '/images/pecas/entrevidas/IMG_2767.JPg',
    '/images/pecas/entrevidas/IMG_2770.JPg',
    '/images/pecas/entrevidas/IMG_2775.JPg',
    '/images/pecas/entrevidas/IMG_2776.JPg',
    '/images/pecas/entrevidas/IMG_2782 (1).JPg',
    '/images/pecas/entrevidas/IMG_2782.JPg',
    '/images/pecas/entrevidas/IMG_2787.JPg',
    '/images/pecas/entrevidas/IMG_2788.JPg',
    '/images/pecas/entrevidas/IMG_2794.JPg',
    '/images/pecas/entrevidas/IMG_2796.JPg',
    '/images/pecas/entrevidas/IMG_2801.JPg',
    '/images/pecas/entrevidas/IMG_2804.JPg',
    '/images/pecas/entrevidas/IMG_2807.JPg',
    '/images/pecas/entrevidas/IMG_2810.JPg',
    '/images/pecas/entrevidas/IMG_2812.JPg',
    '/images/pecas/entrevidas/IMG_2815.JPg',
    '/images/pecas/entrevidas/IMG_2819.JPg',
    '/images/pecas/entrevidas/IMG_2823.JPg',
    '/images/pecas/entrevidas/IMG_2825.JPg',
    '/images/pecas/entrevidas/IMG_2827.JPg',
    '/images/pecas/entrevidas/IMG_2828.JPg',
    '/images/pecas/entrevidas/IMG_2829.JPg',
    '/images/pecas/entrevidas/IMG_2830.JPg',
    '/images/pecas/entrevidas/IMG_2834.JPg',
    '/images/pecas/entrevidas/IMG_2836.JPg',
    '/images/pecas/entrevidas/IMG_2839.JPg',
    '/images/pecas/entrevidas/IMG_2840.JPg',
    '/images/pecas/entrevidas/IMG_2842.JPg',
    '/images/pecas/entrevidas/IMG_2843.JPg',
    '/images/pecas/entrevidas/IMG_2845.JPg',
    '/images/pecas/entrevidas/IMG_2846.JPg',
    '/images/pecas/entrevidas/IMG_2847.JPg',
    '/images/pecas/entrevidas/IMG_2848.JPg',
    '/images/pecas/entrevidas/IMG_2851.JPg',
    '/images/pecas/entrevidas/IMG_2856.JPg',
    '/images/pecas/entrevidas/IMG_2861.JPg',
    '/images/pecas/entrevidas/IMG_2862.JPg',
    '/images/pecas/entrevidas/IMG_2865.JPg',
    '/images/pecas/entrevidas/IMG_2870.JPg',
    '/images/pecas/entrevidas/IMG_2878.JPg',
    '/images/pecas/entrevidas/IMG_2881.JPg',
    '/images/pecas/entrevidas/IMG_2883.JPg',
    '/images/pecas/entrevidas/IMG_2889.JPg',
    '/images/pecas/entrevidas/IMG_2897.JPg',
    '/images/pecas/entrevidas/IMG_2898.JPg',
    '/images/pecas/entrevidas/IMG_2899.JPg',
    '/images/pecas/entrevidas/IMG_2901.JPg',
    '/images/pecas/entrevidas/IMG_2902.JPg',
    '/images/pecas/entrevidas/IMG_2903.JPg',
    '/images/pecas/entrevidas/IMG_2906.JPg',
    '/images/pecas/entrevidas/IMG_2909.JPg',
    '/images/pecas/entrevidas/IMG_2914.JPg',
    '/images/pecas/entrevidas/IMG_2924.JPg',
    '/images/pecas/entrevidas/IMG_2927.JPg',
    '/images/pecas/entrevidas/IMG_2928.JPg',
    '/images/pecas/entrevidas/IMG_2930.JPg',
    '/images/pecas/entrevidas/IMG_2931.JPg',
    '/images/pecas/entrevidas/IMG_2932.JPg',
    '/images/pecas/entrevidas/IMG_2933.JPg',
    '/images/pecas/entrevidas/IMG_2944.JPg',
    '/images/pecas/entrevidas/IMG_2948.JPg',
    '/images/pecas/entrevidas/IMG_2950.JPg',
    '/images/pecas/entrevidas/IMG_2951.JPg',
    '/images/pecas/entrevidas/IMG_2955.JPg',
    '/images/pecas/entrevidas/IMG_2957.JPg',
    '/images/pecas/entrevidas/IMG_2958.JPg',
    '/images/pecas/entrevidas/IMG_2962.JPg',
    '/images/pecas/entrevidas/IMG_2964.JPg',
    '/images/pecas/entrevidas/IMG_2969.JPg',
    '/images/pecas/entrevidas/IMG_2971.JPg',
    '/images/pecas/entrevidas/IMG_2973.JPg',
    '/images/pecas/entrevidas/IMG_2976.JPg',
    '/images/pecas/entrevidas/IMG_2977.JPg',
    '/images/pecas/entrevidas/IMG_2979.JPg',
    '/images/pecas/entrevidas/IMG_2980.JPg',
    '/images/pecas/entrevidas/IMG_2981.JPg',
    '/images/pecas/entrevidas/IMG_2982.JPg',
    '/images/pecas/entrevidas/IMG_2983.JPg',
    '/images/pecas/entrevidas/IMG_2985.JPg',
    '/images/pecas/entrevidas/IMG_2986.JPg',
    '/images/pecas/entrevidas/IMG_2988 (1).JPg',
    '/images/pecas/entrevidas/IMG_2988.JPg',
    '/images/pecas/entrevidas/IMG_2989.JPg',
    '/images/pecas/entrevidas/IMG_2990.JPg',
    '/images/pecas/entrevidas/IMG_2991.JPg',
    '/images/pecas/entrevidas/IMG_2992 (1).JPg',
    '/images/pecas/entrevidas/IMG_2992.JPg',
    '/images/pecas/entrevidas/IMG_2993.JPg',
    '/images/pecas/entrevidas/IMG_2994.JPg',
    '/images/pecas/entrevidas/IMG_2995.JPg',
    '/images/pecas/entrevidas/IMG_2996.JPg',
    '/images/pecas/entrevidas/IMG_2997.JPg',
    '/images/pecas/entrevidas/IMG_2998 (1).JPg',
    '/images/pecas/entrevidas/IMG_2998.JPg',
    '/images/pecas/entrevidas/IMG_2999 (1).JPg',
    '/images/pecas/entrevidas/IMG_2999 (2).JPg',
    '/images/pecas/entrevidas/IMG_2999.JPg',
    '/images/pecas/entrevidas/IMG_3000 (1).JPg',
    '/images/pecas/entrevidas/IMG_3000.JPg',
    '/images/pecas/entrevidas/IMG_3001 (1).JPg',
    '/images/pecas/entrevidas/IMG_3001.JPg',
    '/images/pecas/entrevidas/IMG_3002.JPg',
    '/images/pecas/entrevidas/IMG_3003.JPg',
    '/images/pecas/entrevidas/IMG_3004.JPg',
    '/images/pecas/entrevidas/IMG_3005.JPg',
    '/images/pecas/entrevidas/IMG_3006.JPg',
    '/images/pecas/entrevidas/IMG_3007.JPg',
    '/images/pecas/entrevidas/IMG_3008.JPg',
    '/images/pecas/entrevidas/IMG_3009.JPg',
    '/images/pecas/entrevidas/IMG_3010.JPg',
    '/images/pecas/entrevidas/IMG_3011.JPg',
    '/images/pecas/entrevidas/IMG_3012.JPg',
    '/images/pecas/entrevidas/IMG_3013.JPg',
    '/images/pecas/entrevidas/IMG_3014.JPg',
    '/images/pecas/entrevidas/IMG_3015.JPg',
    '/images/pecas/entrevidas/IMG_3016.JPg',
    '/images/pecas/entrevidas/IMG_3017.JPg',
    '/images/pecas/entrevidas/palestra-denise-e-teatro-maio-227.jpg',
    '/images/pecas/entrevidas/palestra-denise-e-teatro-maio-234.jpg',
    '/images/pecas/entrevidas/palestra-denise-e-teatro-maio-254.jpg',
    '/images/pecas/entrevidas/palestra-denise-e-teatro-maio-266.jpg',
    '/images/pecas/entrevidas/palestra-denise-e-teatro-maio-268.jpg',
    '/images/pecas/entrevidas/palestra-denise-e-teatro-maio-273.jpg',
    '/images/pecas/entrevidas/palestra-denise-e-teatro-maio-336.jpg',
  ];

  return (
    <>
      <Title>Entrevidas</Title>

      <Text>
        <p>
          <strong>Tema:</strong> Homossexualidade, depressão e autoconhecimento.
        </p>
        <br />
        <p>
          <strong>Período em cartaz:</strong> 2011 a 2014.
        </p>
        <br />
        <p>
          "Cada um tem o seu tempo de despertar.
          <br />
          Cada um tem o direito de fazer as suas escolhas, mas terá que
          submeter-se às suas consequências. Isso é inevitável."
        </p>
        <br />
        <p>
          "Entrevidas" convida você a viajar por três encarnações de seus
          personagens, ensinando que ninguém se liberta de suas mazelas sem
          antes cumprir a lei de causa e efeito.
        </p>
      </Text>
      <br />
      <br />

      <div className='mx-64'>
        <PhotoGallery images={images} />
      </div>
    </>
  );
};
