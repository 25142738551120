import { PhotoGallery } from 'components/PhotoGallery';
import { Text } from 'components/Text';
import { Title } from 'components/Title';

export const ChicoRastroDeLuz: React.FC = () => {
  const images = [
    '/images/pecas/chico-rastro-luz/PICT1076.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0081.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0082.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0086.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0103.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0105.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0106.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0108.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0109.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0110.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0113.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0114.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0117.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0119.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0121.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0122.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0124.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0125.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0126.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0127.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0129.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0130.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0131.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0132.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0133.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0134.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0136.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0137.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0140.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0141.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0142.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0143 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0143.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0144.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0145.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0146.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0147.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0148 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0148.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0150.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0151.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0152.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0154.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0155.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0156.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0157.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0159.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0160.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0161.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0163.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0165.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0166.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0167.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0168.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0169.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0170.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0171.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0172.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0173.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0174.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0175.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0176 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0176.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0177 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0177.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0178 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0178.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0179 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0179.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0180.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0181.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0182 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0182 (2).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0182.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0183 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0183.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0184 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0184.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0185.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0186 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0186.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0187 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0187.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0188 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0188.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0189 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0189.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0190.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0191 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0191.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0192.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0193 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0193.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0194 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0194.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0195.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0196 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0196.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0197.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0198 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0198.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0199 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0199.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0200 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0200.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0201.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0202 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0202.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0203 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0203.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0204.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0205 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0205.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0206 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0206.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0207 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0207.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0208 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0208.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0209.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0210.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0211 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0211.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0212.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0213 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0213.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0214 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0214.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0215 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0215.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0216.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0217 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0217.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0218 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0218.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0219 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0219.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0220 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0220.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0221.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0222 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0222.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0223 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0223.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0224.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0225 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0225.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0226.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0227 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0227.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0228 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0228.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0229 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0229.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0230 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0230.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0231 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0231.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0232.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0233 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0233.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0234 (1).JPG',
    '/images/pecas/chico-rastro-luz/DSC_0234.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0235.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0237.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0238.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0239.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0241.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0242.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0244.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0245.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0247.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0249.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0250.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0252.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0253.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0255.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0256.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0257.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0258.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0260.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0261.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0262.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0264.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0265.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0267.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0268.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0269.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0270.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0271.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0272.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0273.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0274.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0275.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0276.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0277.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0278.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0279.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0280.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0281.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0282.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0284.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0285.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0287.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0288.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0291.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0292.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0293.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0295.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0299.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0300.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0301.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0302.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0303.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0304.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0305.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0306.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0314.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0315.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0316.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0317.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0318.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0319.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0320.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0321.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0322.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0323.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0324.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0325.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0327.JPG',
    '/images/pecas/chico-rastro-luz/DSC_0328.JPG',
    '/images/pecas/chico-rastro-luz/Fotografia0108.jpg',
    '/images/pecas/chico-rastro-luz/Fotografia0109.jpg',
    '/images/pecas/chico-rastro-luz/OgAAAB26LTZxbLoHH.jpg',
    '/images/pecas/chico-rastro-luz/OgAAAC1pCvAg7txJGCcGR6o6ZF.jpg',
    '/images/pecas/chico-rastro-luz/OgAAAPg8dtoibO2Ed.jpg',
    '/images/pecas/chico-rastro-luz/PICT1015.JPG',
    '/images/pecas/chico-rastro-luz/PICT1016.JPG',
    '/images/pecas/chico-rastro-luz/PICT1017.JPG',
    '/images/pecas/chico-rastro-luz/PICT1021.JPG',
    '/images/pecas/chico-rastro-luz/PICT1023.JPG',
    '/images/pecas/chico-rastro-luz/PICT1024.JPG',
    '/images/pecas/chico-rastro-luz/PICT1030.JPG',
    '/images/pecas/chico-rastro-luz/PICT1052.JPG',
    '/images/pecas/chico-rastro-luz/PICT1058.JPG',
    '/images/pecas/chico-rastro-luz/PICT1061.JPG',
    '/images/pecas/chico-rastro-luz/PICT1062.JPG',
    '/images/pecas/chico-rastro-luz/PICT1063.JPG',
    '/images/pecas/chico-rastro-luz/PICT1064.JPG',
    '/images/pecas/chico-rastro-luz/PICT1065.JPG',
    '/images/pecas/chico-rastro-luz/PICT1069.JPG',
    '/images/pecas/chico-rastro-luz/PICT1073.JPG',
    '/images/pecas/chico-rastro-luz/PICT1074.JPG',
    '/images/pecas/chico-rastro-luz/PICT1075.JPG',
  ];

  return (
    <>
      <Title>Chico, Um Rastro de Luz</Title>

      <Text>
        <p>
          <strong>Tema:</strong> Fatos da vida do homem Francisco C. Xavier.
        </p>
        <br />
        <p>
          <strong>Período em cartaz:</strong> 2008 / 2010.
        </p>
        <br />
        <p>
          Numa época quando o Brasil se preparava para comemorar o centenário do
          nascimento do homem símbolo do Amor, o Grupo Teatral Terceiro Milênio,
          numa abordagem singela apresentou "Chico, Um Rastro de Luz", que teve
          como figura central o mineiro Francisco Cândido Xavier, mais conhecido
          por Chico Xavier, objetivando focalizar as virtudes, bem como as
          grandes dificuldades que necessitou superar, para concluir a missão do
          homem de bem.
        </p>
        <br />
        <p>
          Numa linguagem simples, emoldurada pela dança e pela música executada
          ao vivo (banda do Terceiro Milênio), o enredo mostrou fatos e
          passagens pouco conhecidas da vida de Chico, desde o seu nascimento
          até sua morte, no intuito de levar até o público o lado humano e
          principalmente a conquista da humildade que sempre o caracterizou tão
          bem.
        </p>
      </Text>

      <br />
      <br />

      <div className='mx-64'>
        <PhotoGallery images={images} />
      </div>
    </>
  );
};
